import React from 'react';
import 'whatwg-fetch';
import { data } from './data';

export const getAll = async () => {
  let res = await get('https://dani.krogerdev.com/api/entries');
  return res.json();
}

export const getOne = async (id) => {
    let res = get('https://dani.krogerdev.com/api/entry/' + id);
    return res.json();
}

export const putOne = async (data) => {
  let res = post('https://dani.krogerdev.com/api/entry', data);
  return res.json();
}

export const deleteOne =(id)=>{
  console.log("slett")
}
const combineDeep = (obj1, obj2) => {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj2 || obj1;
    }
    return Object.keys(obj2).reduce((obj, key) => {
      let value = obj2[key];
      if (value !== undefined) {
        if (typeof value === 'object') {
          if (value instanceof Headers) {
            if (obj1[key]) {
              value = obj1[key];
              new Headers(obj2[key]).forEach((header, name) => {
                if (value.has(name)) {
                  value.delete(name);
                }
                if (name && header) {
                  value.append(name, header);
                }
              });
            }
          } else {
            value = combineDeep(obj[key], value);
          }
        }

        // eslint-disable-next-line no-param-reassign
        obj[key] = value;
      }
      return obj;
    }, obj1);
  }

  const combineConfigs = (...configs) => {
    return configs.reduce((res, cfg) => {
      return combineDeep(res, cfg);
    });
  }

const getDefaultConfig = (method)  => {
    return combineConfigs({
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      method: method
    });
  }

const get =   async (path, config) => {
    return http(path, 'GET', config);
}

const post = async (path, body) => {
  return http(path, 'POST', {
    body: body
  })
}

const http = async (path, method, config = null) => {
    try {

      const cfg = combineConfigs(getDefaultConfig(), {
        method,
      });

      let res = await fetch(`${path}`, getDefaultConfig(method));

      if (res.ok) {
        return res;
      }

      throw res;
    } catch (e) {
      console.error('HttpService - Request failed: ', e);
      throw e;
    }
  }