import React, { useState } from "react";
import { data } from "./data";
import { getAll, putOne, deleteOne } from "./DaniApi";


export const DaniApp=() =>{
    const [d, setData] = useState([]);
    getAll().then((r) => {
        setData(r);
    });

    const saveOne = () => {
        putOne({
            'home_name': document.getElementById('homename').value,
            'away_name': document.getElementById('awayname').value,
            'home_score': document.getElementById('homescore').value,
            'away_score': document.getElementById('awayscore').value,
            'comment': document.getElementById('comment').value
        }).then((r) => {
            window.location.reload();
        })
    }

    return ( 
    <div>
        <table>
            <thead>
                <tr>
                    <th id="hjemmelag">Lillehammer</th>
                    <th id="mål1">mål</th>
                    <th id="bortelag">Faaberg</th>
                    <th id="mal2">mål</th>
                    <th id="skuddsjanser">skuddsjanser</th>
                    <th>slett</th>
                </tr>
            </thead>
            <tbody>
                {d.map((row, index)=>{
                    return(
                        <tr key={row.id}>
                            <td>{row.home_name}</td>
                            <td>{row.home_score}</td>
                            <td>{row.away_name}</td>
                            <td>{row.away_score}</td>
                            <td>{row.comment}</td>
                            <td><button onClick={()=>{
                                deleteOne(row.id)
                            }}>slett</button></td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        <form>
            <label for="fname">Lillehammer:</label><br/>
            <input type="text" id="homename" name="fname" /><br/><br/>

            <label for="fname">mål:</label><br/>
            <input type="text" id="homescore" name="fname" /><br/><br/>

            <label for="fname">Faaberg:</label><br/>
            <input type="text" id="awayname" name="fname" /><br/><br/>

            <label for="fname">mål:</label><br/>
            <input type="text" id="awayscore" name="fname" /><br/><br/>

            <label for="lname">skuddsjanser:</label><br/>
            <input type="text" id="comment" name="lname" /><br/><br/>

            <input type="button" value="Lagre" onClick={saveOne}/>
        </form> 
    </div>
    

    )
}